<template>
  <section class="report">
    <div class="pa-3">
      <h1 class="page-title">Количество встреч</h1>
    </div>
    <reports-filter
      class="mb-10"
      v-model="filterData"
      @submitFilter="handleSubmitFilter"
      :xslData="xslData"
    />
    <report-data-table :data="tableData"></report-data-table>
  </section>
</template>

<script>
import ReportsFilter from "@/components/ReportsFilter.vue";
import ReportDataTable from "@/components/ReportPage/ReportDataTable.vue";

export default {
  name: "NumberMeetings",
  components: { ReportsFilter, ReportDataTable },
  data: () => ({
    filterData: {
      location: null,
      space_name: null,
      capacity: null,
      interval: [
        `${new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)}`,
        `${new Date(
          Date.now() +
            24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)}`,
      ],
    },
  }),
  mounted() {
    this.$store.commit("SET_RANGE_TIME", this.filterData.interval);

    this.$store.dispatch("fetchAllLocation");
    this.$store.commit("SET_REPORT_TABLE", []);
  },
  computed: {
    tableData() {
      let dataCountMeeting = {};
      this.$store.getters.getReportTable.forEach((item) => {
        if (!dataCountMeeting[item.date]) dataCountMeeting[item.date] = {};
        dataCountMeeting[item.date] = this.uniteSpaces(
          dataCountMeeting[item.date],
          item.reservations
        );
      });
      let result = [];
      for (const i in dataCountMeeting) {
        result.push({ date: i, reservations: dataCountMeeting[i] });
      }
      return result;
    },
    xslData() {
      let data = this.$store.getters.getReportTable.map((item) => {
        return {
          Дата: item.date,
          ...item.reservations,
        };
      });
      console.log("xslData = ", data);

      return data;
    },
  },
  methods: {
    handleSubmitFilter() {
      let payload = { ...this.filterData };
      payload.interval = {
        begin: payload.interval[0],
        end: payload.interval[1],
      };
      payload.space_name =
        payload.space_name === "" ? null : payload.space_name;
      this.$store.dispatch("fetchMeetings", payload);
    },
    uniteSpaces(sourceArray, addingArray) {
      for (const i in addingArray) {
        sourceArray[i] = addingArray[i];
      }
      return sourceArray;
    },
  },
};
</script>

<style lang="scss" scoped></style>
