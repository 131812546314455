<template>
  <div class="report-table__container">
    <v-row
      class="report-table__row"
      v-for="row in data"
      :key="String(row.date)"
    >
      <v-col
        class="report-table__column border-column"
        cols="1"
        style="min-width: 120px"
      >
        {{ row.date }}
      </v-col>
      <v-col
        class="report-table__column"
        v-for="(column, j) in columns"
        :key="j"
        v-bind:style="{
          background: `rgba(37,90,180,${setBrightnessCell(
            row.reservations[column]
          )})`,
        }"
      >
        <span :style="{ color: reverseColor }">{{
          row.reservations[column]
        }}</span>
      </v-col>
    </v-row>
    <v-row class="report-table__row">
      <v-col cols="1" style="min-width: 120px"></v-col>
      <v-col
        class="report-table__column border-row"
        v-for="(column, index) in columns"
        :key="index"
      >
        <div class="text-rotate">{{ column }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  props: ["rows", "items", "data"],
  mounted() {},
  data() {
    const columns = [
      // "00:00",
      // "01:00",
      // "02:00",
      // "04:00",
      // "06:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      // "21:00",
      // "22:00",
      // "23:00",
    ];

    const tmpData = [
      "1",
      "11",
      "165",
      "68",
      "79",
      "82",
      "111",
      "189",
      "108",
      "39",
      "123",
      "162",
      "80",
      "177",
      "128",
      "150",
    ];
    const isReverseColor = 0.7;
    let maxSaturation = 2;
    let brightness = 0;
    return {
      columns,
      isReverseColor,
      brightness,
      maxSaturation,
      tmpData,
    };
  },
  computed: {
    maxBr() {
      let a = [];
      for (let i in this.data) {
        a.push(...Object.values(this.data[i].reservations));
      }
      return Math.max(...a.filter((i) => Number.isInteger(i)));
    },
  },
  methods: {
    setBrightnessCell(number) {
      const precentSaturation = number / this.maxBr;
      this.brightness = precentSaturation.toFixed(2);
      return precentSaturation.toFixed(2);
    },
    reverseColor() {
      if (this.brightnessCell >= this.isReverseColor) return "#fff";
      return "#000";
    },
    brightnessCell() {
      return this.brightness;
    },
    dataTable() {
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
$deg-rotate: 90deg;
$column-width: 70px;
$color-border: #e9e9e9;
$color-cell: rgba(37, 90, 180, 1);
$width-border: 0px;

.border-row {
  &:not(:first-child) {
    border-top: 1px solid $color-border;
  }
}
.report-table {
  &__container {
    max-width: 1200px;
    padding: 20px 0;
    margin: 0 auto;
  }

  &__row {
    border-left: $width-border solid $color-border;
    &:nth-child(1) {
      border-top: $width-border solid $color-border;
    }
    flex-wrap: nowrap;
  }

  &__column {
    cursor: pointer;
    border-right: $width-border solid $color-border;
    border-bottom: $width-border solid $color-border;
    width: $column-width;
    min-width: $column-width;
    max-width: $column-width;
  }
}

.border-column {
  border-right: 1px solid $color-border;
}
</style>
